<template>
  <v-container fluid>
    <h3 class="page-title">機台換抽管理</h3>
    <v-row>
      <v-col cols="6" sm="3">
        <v-select
          label="店點"
          hide-details
          v-model="branch"
          :items="[
            { text: '請選擇', value: '', disabled: true },
            { text: '文賢店', value: '文賢' },
            { text: '崇善店', value: '崇善' },
            { text: '奇美店', value: '奇美' },
          ]"
          outlined
          dense
        ></v-select>
      </v-col>
      <v-col cols="6" sm="3">
        <v-text-field
          v-model="keyword"
          label="關鍵字(機台)"
          outlined
          dense
          clearable
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="filterData"
          :mobile-breakpoint="0"
          item-key="index"
          hide-default-footer
          :footer-props="{
            itemsPerPageText: '每頁幾筆',
            itemsPerPageOptions: [-1],
          }"
          :height="'80vh'"
          fixed-header
        >
          <template v-slot:item.product="{ item }">
            <div class="my-1">
              {{ mapProducts[item.productId] }}
              {{ mapProductPoints[item.productId] }}
            </div>
          </template>
          <template v-slot:item.awards="{ item }">
            {{ !!item.awards?.length ? _.sumBy(item.awards, "lottery") : null }}
          </template>
          <template v-slot:item.hasSet="{ item }">
            <v-checkbox
              class="empty-label"
              :input-value="item.awards?.length ? true : false"
              disabled
            ></v-checkbox>
          </template>
          <template v-slot:item.lottery="{ item }">
            <v-btn
              v-if="
                !item.totalDraws &&
                !item.remainDraws &&
                (!item.awards || item.awards?.length === 0)
              "
              @click="openDialog(item, 'lotterySetting')"
              color="success"
              class="my-1 mx-2"
            >
              建立
            </v-btn>
            <v-btn
              v-else
              @click="openDialog(item, 'lotterySetting')"
              color="secondary"
              class="my-1 mx-2"
              :disabled="!allowAdvancedEdit"
            >
              編輯
            </v-btn>
            <v-btn @click="changeLottery(item)" color="error" class="my-1 mx-2">
              換套
            </v-btn>
          </template>
          <template v-slot:item.trade="{ item }">
            <v-btn
              :disabled="!item.totalDraws || item.remainDraws <= 0"
              @click="openDialog(item, 'lotteryCheck')"
              color="primary"
            >
              換抽
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <DialogLotterySetting
      v-if="dialog.lotterySetting"
      v-model="dialog.lotterySetting"
      :stall="selectedStall"
      @load="load"
    />
    <DialogLotteryCheck
      v-if="dialog.lotteryCheck"
      v-model="dialog.lotteryCheck"
      :stall="selectedStall"
      :product="mapProducts[selectedStall.productId]"
      @load="load"
    />
  </v-container>
</template>

<script>
import DialogLotterySetting from "@/components/dashboard/lottery/DialogLotterySetting.vue";
import DialogLotteryCheck from "@/components/dashboard/lottery/DialogLotteryCheck.vue";

import machineNum from "@/config/machineNum.json";
import util from "@/mixins/util";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import _ from "lodash";
dayjs.extend(isBetween);

export default {
  name: "Stall",
  mixins: [util],
  components: { DialogLotterySetting, DialogLotteryCheck },
  data: () => ({
    dialog: {
      lotterySetting: false,
      lotteryCheck: false,
    },
    data: [],
    stockMap: {},
    products: [],
    rawProducts: [],
    selectedStall: null,
    branch: null,
    keyword: "",
    remainDraws: null,
  }),
  computed: {
    _: () => _,
    headers() {
      const headers = [
        {
          text: "機台位",
          value: "machine",
          align: "center",
          sortable: false,
          cellClass: "font-weight-regular",
        },
        {
          text: "機台商品",
          value: "product",
          align: "left",
          sortable: false,
        },
        {
          text: "編號",
          value: "setTag",
          align: "center",
          sortable: false,
        },
        {
          text: "總籤數",
          value: "totalDraws",
          align: "left",
          sortable: false,
        },
        {
          text: "剩餘獎項數",
          value: "awards",
          align: "left",
          sortable: false,
        },
        {
          text: "剩餘籤數",
          value: "remainDraws",
          align: "left",
          sortable: false,
        },
        {
          text: "換抽",
          value: "trade",
          align: "center",
          sortable: false,
        },
        {
          text: "設定換抽",
          value: "lottery",
          align: "center",
          sortable: false,
        },
      ];
      return headers;
    },
    mapProductPoints() {
      let products = {};
      this.products.forEach((item) => {
        products[item.value] = item.points ? ` [${item.points}]` : "";
      });
      return products;
    },
    mapProducts() {
      let products = {};
      this.products.forEach((item) => {
        products[item.value] = item.text;
      });
      return products;
    },
    filterData() {
      let data = this.data;

      if (this.keyword) {
        let mapProducts = this.products
          .filter((o) => o.text?.includes(this.keyword) || false)
          .map((o) => o.value);
        data = data.filter(
          (item) =>
            item.machine == this.keyword || mapProducts?.includes(item.product)
        );
      }
      return data;
    },
    allowAdvancedEdit() {
      return ["管理者", "店長", "主管"].includes(this.$store.state.user.角色);
    },
  },
  async created() {
    await this.getProducts();
    this.branch = this.$store.state.branch;
  },
  methods: {
    openDialog(stall, type) {
      this.dialog[type] = true;
      this.selectedStall = stall;
    },
    async getProducts() {
      const res = await this.axios.get(`/product`);
      this.rawProducts = res.data;
      let product = res.data.map((item) => ({
        text: item.name,
        value: item._id,
        points: item.points,
      }));
      product = _.uniqBy(product, "text");
      product.unshift({ text: "-", value: "" });
      this.products = product;
    },
    async getStock() {
      const { data } = await this.axios.get(`/stock`, {
        params: {
          branch: this.branch,
        },
      });
      this.stockMap = _.keyBy(data, "productId");
    },
    async load() {
      if (!this.ready && !this.branch) return;

      this.$vloading.show();
      const { data } = await this.axios.get(`/stall`);
      // await this.getStock();

      // get array from 1 to 67
      let stall = machineNum[this.branch] ?? [];
      stall = stall.map((item) => ({
        branch: this.branch,
        machine: item.toString(),
        productId: "",
        ..._.find(data, { branch: this.branch, machine: item.toString() }),
      }));

      this.data = stall;
      this.$vloading.hide();
    },
    async update(stall, key, e) {
      await this.axios.put(`/stall/${stall.branch}/${stall.machine}`, {
        [key]: e.value,
      });
    },
    async changeLottery(item) {
      const check = await this.$dialog.confirm({
        text: `<p class="headline"> 確認移除
      <span class="red--text darken-4--text">${item.branch}${item.machine}</span>
      的換抽，並建立新換抽？</p>`,
      });
      if (!check) return;

      try {
        this.$vloading.show();

        await this.axios.put(`/stall/${item.branch}/${item.machine}`, {
          setTag: "",
          awards: [],
          totalDraws: null,
          remainDraws: null,
        });
        await this.load();
        await this.$nextTick();

        const newItem = this.data.find((o) => o._id === item._id);

        this.openDialog(newItem, "lotterySetting");
      } catch (error) {
        console.log(error);
        this.$toast.error(error.response?.data?.message || "換套失敗");
      }
    },
  },
  watch: {
    branch(val) {
      this.$store.commit("setState", { branch: val });
      this.load();
    },
  },
};
</script>

<style scoped lang="scss">
.font-small {
  font-size: 11px;
}

.empty-label {
  position: relative;

  ::v-deep .v-input__slot {
    justify-content: center;
  }
}
</style>
