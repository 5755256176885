<template>
  <v-dialog v-model="open" persistent>
    <v-card>
      <v-card-title class="mb-4">
        設定換抽 —— {{ stall.branch }}店 {{ stall.machine }} 機台
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="auto" class="text-center ml-auto">
            <v-btn color="primary" @click="resetPayload" depressed outlined>
              一鍵清空
            </v-btn>
            <v-btn
              color="success"
              @click="resetPayload(initialAwardState)"
              class="ml-3"
              depressed
            >
              復原
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="payload.setTag"
              label="編號"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="6" md="4" class="text-center mr-auto">
            <v-text-field
              v-model.number="payload.totalDraws"
              @change="updateRemain"
              type="number"
              label="換抽總數量"
              dense
              outlined
              hide-details
              clearable
              filled
            ></v-text-field>
          </v-col>
          <v-col cols="6" md="4" class="text-center mr-auto">
            <v-text-field
              v-model.number="payload.remainDraws"
              type="number"
              label="剩餘抽數"
              dense
              outlined
              hide-details
              clearable
              filled
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <v-row v-for="(item, n) of payload.awards" :key="n" align="center">
          <v-col cols="3" class="text-center">
            <v-text-field
              v-model="item.name"
              label="獎項名稱"
              dense
              outlined
              hide-details
              clearable
            ></v-text-field>
          </v-col>
          <v-col cols="5">
            <v-autocomplete
              label="商品"
              :value="item.productId"
              :items="productOptions"
              @change="updateProductId(item, $event)"
              dense
              outlined
              hide-details
            ></v-autocomplete>
          </v-col>
          <v-col cols="3" class="text-center">
            <v-text-field
              v-model.number="item.lottery"
              type="number"
              label="數量"
              dense
              outlined
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="1" class="text-left">
            <v-icon class="pointer" color="error" @click="removeItem(n)">
              mdi-close
            </v-icon>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-btn large block color="primary" @click="addItem">
              增加一筆
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn text color="error" @click="close" class="bt-text-large">
          取消
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="create" class="bt-text-large">
          儲存
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import dayjs from "dayjs";
import _ from "lodash";

export default {
  name: "DialogStockScrapped",
  props: ["stall", "branch", "value"],
  data: () => ({
    amount: null,
    comment: "",
    products: [],
    payload: {},
  }),
  computed: {
    open: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    hasSet() {
      return (
        this.stall.totalDraws &&
        // this.stall.remainDraws &&
        (!!this.stall.awards || this.stall.awards?.length !== 0)
      );
    },
    productOptions() {
      return this.products.map((product) => {
        return { text: product.name, value: product._id };
      });
    },
    initialAwardState() {
      const { awards, totalDraws, remainDraws, setTag } = _.cloneDeep(
        this.stall
      );
      return { awards, totalDraws, remainDraws, setTag };
    },
    // allowAdvancedEdit() {
    //   return ["管理者", "店長", "主管"].includes(this.$store.state.user.角色);
    // },
  },
  created() {
    this.getProducts();
    this.resetPayload(this.initialAwardState);
  },
  methods: {
    updateRemain(val) {
      this.$set(this.payload, "remainDraws", val);
    },
    updateProductId(item, event) {
      // console.log(item, event);
      item.productId = event;
    },
    removeItem(n) {
      this.payload.awards.splice(n, 1);
    },
    addItem() {
      this.payload.awards.push({
        name: "",
        product: null,
        lottery: 0,
      });
    },
    async getProducts() {
      let { data } = await this.axios.get(`/product`);
      data = data.filter((o) => o.name);
      this.products = data;
    },
    async create() {
      // 驗證
      if (this.payload.awards.some((award) => !award.productId)) {
        return this.$toast.error("請填入獎項商品");
      }
      if (
        this.payload.awards.some(
          (award) => award.lottery !== 0 && !award.lottery
        )
      ) {
        return this.$toast.error("請填入獎項數量");
      }
      if (!this.payload.totalDraws && this.payload.totalDraws !== 0) {
        return this.$toast.error("請填寫總抽數");
      }

      try {
        this.$vloading.show();

        let process = [];

        if (this.payload.setTag && this.payload.setTag !== this.stall.setTag) {
          process.push(
            this.axios.put(
              `/stall/${this.stall.branch}/${this.stall.machine}`,
              {
                setTag: this.payload.setTag,
              }
            )
          );
        }

        if (
          this.payload.totalDraws &&
          this.payload.totalDraws !== this.stall.totalDraws
        ) {
          process.push(
            this.axios.put(`/stall/${this.stall._id}/update-totalDraws`, {
              amount: this.payload.totalDraws,
            })
          );
        }
        if (
          this.payload.remainDraws &&
          this.payload.remainDraws !== this.stall.remainDraws
        ) {
          process.push(
            this.axios.put(`/stall/${this.stall._id}/update-remainDraws`, {
              amount: this.payload.remainDraws,
            })
          );
        }

        this.payload.awards.forEach((award) => {
          const isAlreadyExist = _.some(this.stall.awards, {
            productId: award.productId,
          });
          // 更新
          if (award.productId && isAlreadyExist) {
            process.push(
              this.axios.put(`/stall/${this.stall._id}/update-award`, {
                productId: award.productId,
                newAward: award,
              })
            );
            return;
          }

          // 新增
          if (award.productId) {
            process.push(
              this.axios.put(`/stall/${this.stall._id}/insert-award`, award)
            );
            return;
          }
        });

        // 刪除
        if (this.stall?.awards) {
          this.stall.awards.forEach((award) => {
            const isExist = _.some(this.payload.awards, {
              productId: award.productId,
            });
            if (!isExist) {
              process.push(
                this.axios.put(`/stall/${this.stall._id}/remove-award`, {
                  productId: award.productId,
                })
              );
            }
          });
        }
        const result = await Promise.all(process);
        console.log("re", result);
        this.$toast.success("儲存完成");
        this.$vloading.hide();

        this.$emit("load");
        this.open = false;
      } catch (error) {
        console.log(error);
        this.$toast.error("儲存失敗");
        this.$vloading.hide();
      }
    },
    close() {
      this.resetPayload();
      this.open = false;
    },
    resetPayload(payload) {
      if (payload) {
        this.payload = {
          awards: payload.awards ?? [],
          totalDraws: payload.totalDraws,
          remainDraws: payload.remainDraws,
          setTag: payload.setTag,
        };
        return;
      }
      this.payload = {
        awards: [],
        totalDraws: null,
        remainDraws: null,
      };
    },
  },
};
</script>

<style></style>
