<template>
  <v-dialog v-model="open" persistent width="400">
    <v-card>
      <v-card-title>換抽幾抽</v-card-title>
      <v-card-text>
        <!--  <div class="mb-3">
          <v-text-field
            label="原總籤數"
            outlined
            dense
            disabled
            hide-details
            :value="stall.totalDraws"
          ></v-text-field>
        </div> -->
        <div class="mb-3">
          <v-text-field
            label="剩餘籤數"
            outlined
            dense
            disabled
            hide-details
            :value="stall.remainDraws"
          ></v-text-field>
        </div>
        <!-- <div class="mb-3">
          <v-text-field
            type="number"
            v-model.number="totalDraws"
            label="總籤數"
            outlined
            dense
            clearable
            hide-details
          ></v-text-field>
        </div> -->
        <div class="mb-3">
          <v-text-field
            type="number"
            v-model.number="amount"
            label="換抽幾抽"
            outlined
            dense
            clearable
            hide-details
          ></v-text-field>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn text class="bt-text-large" color="error" @click="open = false">
          取消
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn text class="bt-text-large" color="primary" @click="confirm">
          確定
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DialogProductCheck",
  props: ["value", "stall", "product"],
  data: () => ({
    amount: 1,
    // totalDraws: null,
    // remainDraws: null,
  }),
  computed: {
    open: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  created() {},
  methods: {
    async confirm() {
      try {
        if (!this.amount) {
          this.$toast.error("請輸入換抽數量");
          return;
        }
        if (this.amount > this.stall.remainDraws) {
          this.$toast.error("換抽數量不可大於剩餘籤數");
          return;
        }

        await this.axios.put(
          `/stall/${this.stall.branch}/${this.stall.machine}`,
          {
            remainDraws: this.stall.remainDraws - this.amount,
          }
        );
        this.$toast.success("更新完成");
        this.$emit("load");
        this.open = false;
      } catch (error) {
        console.log(error);
        this.$toast.error("更新失敗");
      }
    },
  },
};
</script>

<style></style>
