var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('h3',{staticClass:"page-title"},[_vm._v("機台換抽管理")]),_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"3"}},[_c('v-select',{attrs:{"label":"店點","hide-details":"","items":[
          { text: '請選擇', value: '', disabled: true },
          { text: '文賢店', value: '文賢' },
          { text: '崇善店', value: '崇善' },
          { text: '奇美店', value: '奇美' },
        ],"outlined":"","dense":""},model:{value:(_vm.branch),callback:function ($$v) {_vm.branch=$$v},expression:"branch"}})],1),_c('v-col',{attrs:{"cols":"6","sm":"3"}},[_c('v-text-field',{attrs:{"label":"關鍵字(機台)","outlined":"","dense":"","clearable":"","hide-details":""},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filterData,"mobile-breakpoint":0,"item-key":"index","hide-default-footer":"","footer-props":{
          itemsPerPageText: '每頁幾筆',
          itemsPerPageOptions: [-1],
        },"height":'80vh',"fixed-header":""},scopedSlots:_vm._u([{key:"item.product",fn:function({ item }){return [_c('div',{staticClass:"my-1"},[_vm._v(" "+_vm._s(_vm.mapProducts[item.productId])+" "+_vm._s(_vm.mapProductPoints[item.productId])+" ")])]}},{key:"item.awards",fn:function({ item }){return [_vm._v(" "+_vm._s(!!item.awards?.length ? _vm._.sumBy(item.awards, "lottery") : null)+" ")]}},{key:"item.hasSet",fn:function({ item }){return [_c('v-checkbox',{staticClass:"empty-label",attrs:{"input-value":item.awards?.length ? true : false,"disabled":""}})]}},{key:"item.lottery",fn:function({ item }){return [(
              !item.totalDraws &&
              !item.remainDraws &&
              (!item.awards || item.awards?.length === 0)
            )?_c('v-btn',{staticClass:"my-1 mx-2",attrs:{"color":"success"},on:{"click":function($event){return _vm.openDialog(item, 'lotterySetting')}}},[_vm._v(" 建立 ")]):_c('v-btn',{staticClass:"my-1 mx-2",attrs:{"color":"secondary","disabled":!_vm.allowAdvancedEdit},on:{"click":function($event){return _vm.openDialog(item, 'lotterySetting')}}},[_vm._v(" 編輯 ")]),_c('v-btn',{staticClass:"my-1 mx-2",attrs:{"color":"error"},on:{"click":function($event){return _vm.changeLottery(item)}}},[_vm._v(" 換套 ")])]}},{key:"item.trade",fn:function({ item }){return [_c('v-btn',{attrs:{"disabled":!item.totalDraws || item.remainDraws <= 0,"color":"primary"},on:{"click":function($event){return _vm.openDialog(item, 'lotteryCheck')}}},[_vm._v(" 換抽 ")])]}}])})],1)],1),(_vm.dialog.lotterySetting)?_c('DialogLotterySetting',{attrs:{"stall":_vm.selectedStall},on:{"load":_vm.load},model:{value:(_vm.dialog.lotterySetting),callback:function ($$v) {_vm.$set(_vm.dialog, "lotterySetting", $$v)},expression:"dialog.lotterySetting"}}):_vm._e(),(_vm.dialog.lotteryCheck)?_c('DialogLotteryCheck',{attrs:{"stall":_vm.selectedStall,"product":_vm.mapProducts[_vm.selectedStall.productId]},on:{"load":_vm.load},model:{value:(_vm.dialog.lotteryCheck),callback:function ($$v) {_vm.$set(_vm.dialog, "lotteryCheck", $$v)},expression:"dialog.lotteryCheck"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }